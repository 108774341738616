@import "../../node_modules/@fortawesome/fontawesome-svg-core/styles.css";
@import "../../node_modules/bulma/css/bulma.css";
@import "./accordion.scss";


.is-centered {
  align-items: center;
}

.tags {
  margin-bottom: 0rem !important;
}

.tags .tag {
  margin-bottom: 0rem !important;
  margin-right: 0.4rem !important;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.ml-auto {
  margin-left: auto;
}

.is-no-wrap {
  white-space: nowrap;
}

.is-print-only {
  display: none;
}

.is-flex {
  align-items: center;
  flex-wrap: wrap;
}

.is-flex.space-between {
  justify-content: space-between;
}

.is-small {
  font-size: 0.8rem;
}

.is-very-small {
  font-size: 0.6rem !important;
}

.button.timer {
  padding-left: .8rem; 
  padding-right: .8rem;
}

.button.is-very-small,
.buttons.is-very-small {
  font-size: 0.6rem;
}

.modal-card-foot {
  justify-content: flex-end;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  .is-hidden-print {
    display: none !important;
  }

  .is-print-only {
    display: block !important;
  }
  td.is-print-only,
  th.is-print-only {
    display: table-cell !important;
  }
  .is-hidden-desktop {
    display: none !important;
  }
}
