.accordions .accordion:not(:first-child) .accordion-header {
  border-radius: 0;
}

.accordions .accordion:not(:last-child) {
  margin-bottom: 0;
}

.accordions .accordion {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  font-size: 1rem;
}

.accordions .accordion .toggle,
.accordions .accordion [data-action="toggle"] {
  cursor: pointer;
}

.accordions .accordion .accordion-header {
  align-items: center;
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: space-between;
  line-height: 1.25;
  padding: 1rem 0.75rem;
  position: relative;
}

.accordions .accordion .accordion-header + .accordion-body {
  overflow-y: hidden;
  max-height: 0;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  -webkit-transition: max-height 0.2s ease;
  -moz-transition: max-height 0.2s ease;
  -o-transition: max-height 0.2s ease;
  transition: max-height 0.2s ease;
}

.accordions .accordion.is-active .accordion-body {
  max-height: 100em;
}

.accordions .accordion .accordion-header + .accordion-body .accordion-content {
  padding: 1em 1.25em;
}

.accordions .accordion:not(:last-child) .accordion-content {
  border-radius: 0;
}
